@import "./Variables.scss";

.MuiAlert-filledError {
    background-color: $errorLight !important;
    color: $error !important;
    display: flex;
    align-items: center;
    .MuiAlert-icon {
        padding: 0 !important;
        margin-right: $spacing + px;
    }
}
