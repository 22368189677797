@import "./Variables.scss";

.MuiButton {
  &-root {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none !important;
    padding: 12px 24px !important;
    border-radius: 6px !important;
    width: 100%;
    &.Mui-disabled {
      border: 1px solid $border !important;
      background-color: $disable !important;
      color: $placeholder !important;
    }
    .MuiSvgIcon-root {
      font-size: 20px;
    }
    .MuiSvgIcon-root:nth-child(odd) {
      margin-right: 8px;
    }
    .MuiSvgIcon-root:nth-child(even) {
      margin-left: 8px;
    }
  }
  &-contained,
  &-contained:hover,
  &-contained:focus {
    color: $white !important;
    background-color: $primary !important;
    border: 1px solid $primary !important;
  }
  &-outlined {
    color: $primary !important;
    background-color: $white !important;
    border: 1px solid $primary !important;
    &:hover,
    &:focus,
    &:active {
      background-color: $primary !important;
      color: $white !important;
    }
  }
  &-text {
    padding: 0 !important;
    font-size: 16px !important;
    line-height: 22.88px !important;
    font-weight: 600 !important;
    background-color: transparent !important;
    &.button-text-white {
      color: $white;
    }
  }
  &-contained.deleted,
  &-contained.deleted:hover,
  &-contained.deleted:focus {
    background-color: $error !important;
    border: 1px solid $error !important;
  }
  &-outlined.canceled {
    color: $neutral !important;
    border: 1px solid $border !important;
    &:hover,
    &:focus,
    &:active {
      background-color: $neutral !important;
      border: 1px solid $neutral !important;
      color: $white !important;
    }
  }
}

.search-and-filter {
  .MuiButton {
    &-root {
      padding: 16px 24px !important;
    }
  }
}

@media (min-width: 0) {
  .button-print-popover {
    .MuiPaper-root {
      margin-top: $spacing * 2 + px;
      width: 100%;
      min-width: 190px;
      max-width: calc(100% - 64px);
      left: unset !important;
      right: 40px;
    }
    &-items {
      cursor: pointer;
      &:hover {
        color: $primary !important;
      }
    }
  }
}
@media (min-width: 768px) {
  .button-print-popover {
    .MuiPaper-root {
      max-width: 190px;
    }
  }
  .button-create-form-popover {
    .MuiPaper-root {
      max-width: 250px;
    }
  }
}
