@import "./Variables.scss";

.modal-md {
  .modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 700px;
    width: 70%;
    background-color: $white;
    border-radius: 10px;
    padding: $spacing * 3 + px;
    outline: none;
  }
}
.modal-lg {
  .modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1200px;
    width: 70%;
    background-color: $white;
    border-radius: 10px;
    padding: $spacing * 3 + px;
    outline: none;
  }
}

.modal-image-rotate-editor {
  &-image-container {
    text-align: center;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media (min-width: 768px) {
  .modal-image-rotate-editor {
    &-image-container {
      width: 300px;
      height: 300px;
    }
  }
}
@media (min-width: 1024px) {
  .modal-image-rotate-editor {
    &-image-container {
      width: 400px;
      height: 400px;
    }
  }
}
