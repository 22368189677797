@import "./Variables.scss";

.MuiFormControl-root {
  margin: 0 !important;
  width: 100% !important;
  .MuiInputBase-formControl {
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 400 !important;
    padding: 0 !important;
    border: 0 !important;
  }
  .input-error {
    .MuiSelect-select {
      border: 2px solid $error !important;
    }
  }
  .MuiSelect-select {
    height: 24px !important;
    width: 94%;
    padding: $spacing * 2 + px $spacing * 2 + px $spacing * 2 + px $spacing * 2 +
      px;
    border: 1px solid $border !important;
    border-radius: 4px !important;
    .dropdown-selected-text {
      -webkit-text-fill-color: $black;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }
}
.MuiAutocomplete {
  &-root {
    .input-error {
      .MuiAutocomplete-input {
        border: 2px solid $error !important;
      }
    }
  }
  &-paper {
    padding: 0;
    max-height: 300;
    border-radius: 4px;
    margin-top: $spacing;
    overflow-y: "auto";
    box-shadow: $shadowSm !important;
  }
  &-popper {
    z-index: 9 !important;
  }
  &-listbox {
    padding: 0;
  }
  &-option {
    padding: $spacing + px $spacing * 2 + px !important;
    background-color: $white !important;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $black;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block !important;
    &.Mui-focused,
    &:hover {
      background-color: $backgroundLight !important;
    }
  }
  &-inputRoot {
    padding: $spacing * 2 + px !important;
    &::placeholder {
      color: $placeholder !important;
      -webkit-text-fill-color: $placeholder !important;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $placeholder !important;
      -webkit-text-fill-color: $placeholder !important;
    }
    &::-ms-input-placeholder {
      color: $placeholder !important;
      -webkit-text-fill-color: $placeholder !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0 !important;
    }
  }
  &-input {
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 400 !important;
    color: $black !important;
    height: 24px !important;
    padding: $spacing * 2 + px $spacing * 4 + px $spacing * 2 + px $spacing * 2 +
      px !important;
    border: 1px solid $border !important;
    border-radius: 4px !important;
  }
  &-loading {
    text-align: center;
  }
  &-endAdornment {
    .MuiIconButton-root {
      background: transparent !important;
    }
  }
}
