$spacing: 8;

// Primary
$primary: #0caaa9;
$secondary: #ceeeed;
$neutral: #757577;
$black: #000;
$white: #fff;
// Grey scale
$header: #303030;
$subHeader: #757577;
$border: #ddd;
$background: #e3e3e3;
$backgroundLight: #fafafa;
$placeholder: #929292;
// Support
$success: #468847;
$successLight: #dff0d8;
$error: #e5050b;
$errorLight: #fdebeb;
$focused: #4984c9;
$hovered: #d9def7;
$warning: #f0ad48;
$warningLight: #fbdca4;
$disable: #d3d2d0;
// Icon
$mainIcon: #929292;
$textFieldIcon: #0caaa9;
$selectedIcon: #4984c9;
$editIcon: #f0ad48;
$deleteIcon: #e5050b;

// shadow
$shadowXs: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
$shadowSm: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
  0px 1px 3px 0px rgba(34, 34, 33, 0.1);
$shadowMd: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
  0px 4px 8px -2px rgba(34, 34, 33, 0.1);
$shadowLg: 0px 4px 6px -2px rgba(34, 34, 33, 0.06),
  0px 12px 16px -4px rgba(34, 34, 33, 0.1);
$shadowXl: 0px 8px 8px -4px rgba(34, 34, 33, 0.04),
  0px 20px 24px -4px rgba(34, 34, 33, 0.1);
$shadow2xl: 0px 24px 48px -12px rgba(34, 34, 33, 0.25);
$shadow3xl: 0px 32px 64px -12px rgba(34, 34, 33, 0.2);
