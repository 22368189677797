@import "./Variables.scss";

.MuiInputBase-root {
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  color: $black !important;
  border-radius: 4px !important;
  border: 1px solid $border !important;
  padding: 16px 16px !important;
  width: 100%;
  &:after,
  &:before {
    border-bottom: 0 !important;
  }
  .MuiInput-input {
    padding: 0 !important;
    height: 24px !important;
    &::placeholder {
      color: $placeholder !important;
      -webkit-text-fill-color: $placeholder !important;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $placeholder !important;
      -webkit-text-fill-color: $placeholder !important;
    }
    &::-ms-input-placeholder {
      color: $placeholder !important;
      -webkit-text-fill-color: $placeholder !important;
    }
  }
  .MuiInputAdornment-root {
    .MuiButtonBase-root {
      padding: 0 !important;
      background-color: transparent !important;
    }
  }
  &.input-secure {
    .MuiInput-input {
      -webkit-text-security: disc;
    }
  }
  &.input-error {
    border: 2px solid $error !important;
  }
  &.Mui-disabled {
    background-color: $disable !important;
  }
  &.input-disable-text {
    .Mui-disabled {
      color: $black !important;
      -webkit-text-fill-color: $black !important;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input[type="number"] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 1;
  }
}

.input-label {
  color: $neutral;
  margin-bottom: $spacing + px;
  display: flex;
  align-items: center;
  &-require {
    color: $error;
  }
}

.error-message {
  color: $error;
}

// Date picker
.desktop-date-picker {
  width: 100%;
  border: 1px solid $border !important;
  border-radius: 4px !important;
  &-error {
    border: 2px solid $error !important;
  }
  &-not-selected {
    .MuiInputBase-root {
      color: $placeholder !important;
    }
  }
  .MuiInputBase-input {
    padding: $spacing * 2 + px $spacing * 2 + px $spacing * 2 + px $spacing * 2 +
      px;
    opacity: 1 !important;
    height: 24px !important;
    &::placeholder {
      color: $placeholder !important;
      -webkit-text-fill-color: $placeholder !important;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $placeholder !important;
      -webkit-text-fill-color: $placeholder !important;
    }
    &::-ms-input-placeholder {
      color: $placeholder !important;
      -webkit-text-fill-color: $placeholder !important;
    }
    &.Mui-disabled {
      -webkit-text-fill-color: $black !important;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border-style: none;
  }
  .MuiInputAdornment-root {
    margin-left: 0;
    margin-right: $spacing * 3 + px;
  }
}
.css-1qrp8zh-MuiButtonBase-root-MuiPickersDay-root {
  &:not(.Mui-selected) {
    background-color: transparent !important;
    border: 1px solid $primary !important;
  }
}
.MuiPickersDay-root {
  font-size: 13px !important;
  line-height: 20.41px !important;
  font-weight: 400 !important;
  &:focus {
    background-color: transparent !important;
    border: 1px solid $primary !important;
  }
  &.Mui-selected {
    background-color: $primary !important;
  }
}
.MuiYearCalendar-root {
  font-size: 13px !important;
  line-height: 20.41px !important;
  font-weight: 400 !important;
  .Mui-selected {
    background-color: $primary !important;
  }
}
.mobile-date-time-picker {
  .MuiPickersToolbar-root {
    .MuiTypography-root {
      font-size: 16px !important;
      color: $black !important;
      font-weight: 500 !important;
      line-height: 24px !important;
    }
    .css-1kvsb8t-MuiTypography-root {
      font-size: 16px !important;
      color: $black !important;
      font-weight: 600 !important;
      line-height: 24px !important;
    }
    .MuiDateTimePickerToolbar-dateContainer,
    .MuiDateTimePickerToolbar-timeContainer {
      width: 50%;
    }
    .MuiDateTimePickerToolbar-dateContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .MuiButtonBase-root {
        justify-content: flex-start;
        align-items: center;
      }
    }
    .MuiDateTimePickerToolbar-timeContainer {
      justify-content: flex-end;
      .MuiDateTimePickerToolbar-timeDigitsContainer {
        align-items: center;
        .MuiDateTimePickerToolbar-separator {
          margin-left: $spacing + px;
          margin-right: $spacing + px;
        }
      }
      .MuiDateTimePickerToolbar-ampmSelection {
        margin-right: 0 !important;
        .Mui-selected {
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          background-color: $primary !important;
          color: $white !important;
          border-radius: 100%;
          width: 32px !important;
          height: 32px !important;
        }
      }
    }
  }
  .MuiDateCalendar-root {
    max-height: 300px !important;
  }
  .MuiPickersSlideTransition-root {
    min-height: 200px !important;
  }
  .MuiPickersLayout-contentWrapper {
    .MuiDateTimePickerTabs-root {
      .MuiTabs-scroller {
        .MuiTabs-flexContainer {
          .MuiButtonBase-root {
            color: $mainIcon !important;
          }
          .Mui-selected {
            color: $primary !important;
          }
        }
        .MuiTabs-indicator {
          background-color: $primary !important;
        }
      }
    }
    .MuiTimeClock-root {
      .MuiClock-pin,
      .MuiClockPointer-root {
        background-color: $primary !important;
        .MuiClockPointer-thumb {
          background-color: $white !important;
          border-color: $primary !important;
        }
      }
      .MuiClockNumber-root {
        &.Mui-selected {
          background-color: $primary !important;
        }
      }
    }
  }
  .MuiDialogActions-root {
    padding-top: $spacing * 2 + px;
    padding-bottom: $spacing * 2 + px;
    .MuiButtonBase-root:first-child {
      color: $neutral !important;
      &:hover,
      &:active {
        color: $primary !important;
      }
    }
    .MuiButtonBase-root:last-child {
      color: $primary !important;
    }
  }
  .css-2jurxj-MuiDayCalendar-slideTransition {
    min-height: 100%;
  }
}
.desktop-date-time-picker {
  .MuiDateCalendar-root {
    max-height: 300px !important;
  }
  .MuiPickersSlideTransition-root {
    min-height: 200px !important;
  }
  .MuiMultiSectionDigitalClock-root {
    max-height: 300px !important;
    .MuiList-root {
      width: 100% !important;
      .MuiMenuItem-root {
        margin: $spacing / 2 + px;
        width: 32px !important;
        height: 32px !important;
        font-size: 13px !important;
        line-height: 20.41px !important;
        font-weight: 400 !important;
        &:hover {
          border-radius: 100% !important;
        }
      }
      &::after {
        display: none;
      }
    }
    .Mui-selected {
      background-color: $primary !important;
      color: $white !important;
      border-radius: 100% !important;
    }
  }
  .MuiDialogActions-root {
    padding-top: $spacing * 2 + px !important;
    padding-bottom: $spacing * 2 + px !important;
    .MuiButtonBase-root {
      color: $primary !important;
    }
  }
}

// Switcher, Radio
.MuiFormControlLabel-root {
  .MuiSwitch-root {
    padding-left: $spacing + px;
    .MuiSwitch-switchBase {
      background-color: transparent !important;
    }
  }
  .MuiFormControlLabel-label {
    font-size: 13px;
    line-height: 20.41px;
    font-weight: 600;
    color: $placeholder !important;
  }
  .MuiSwitch-thumb {
    background-color: $white;
  }
  .Mui-checked {
    & + .MuiSwitch-track {
      background-color: $focused !important;
      opacity: 1 !important;
    }
  }
  .MuiSwitch-track {
    background-color: $placeholder !important;
  }
  .MuiRadio-root,
  .MuiCheckbox-root {
    & + .MuiFormControlLabel-label {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400px;
      color: black !important;
    }
    &:hover {
      background-color: transparent !important;
    }
    padding-top: 0;
    padding-bottom: 0;
  }
}
// Textarea
.input-text-area {
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  color: $black !important;
  border-radius: 4px !important;
  border: 1px solid $border !important;
  outline: none !important;
  padding: 16px 16px !important;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent !important;
  &::placeholder {
    color: $placeholder !important;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $placeholder !important;
  }
  &::-ms-input-placeholder {
    color: $placeholder !important;
  }
}
