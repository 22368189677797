@import "./Variables.scss";

.sidebar-menu {
    &-item {
        &.active {
            background-color: $secondary;
            color: $primary;
            border-radius: 4px;
        }
    }
}

.drawer-menu {
    .MuiDrawer-paper {
        width: 320px;
        max-width: 80%;
    }
}
