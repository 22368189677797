@import "./Variables.scss";

.MuiTablePagination-root {
  .MuiInputBase-root {
    width: auto !important;
    border-style: none !important;
    padding: 0 !important;
    margin-left: $spacing + px;
    margin-right: $spacing * 3 + px;
    .MuiSelect-select {
      background-color: transparent !important;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20.41px;
      color: $black;
    }
  }
  .MuiTablePagination-selectLabel {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20.41px;
    color: $subHeader;
  }
  .MuiTablePagination-displayedRows {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.75px;
    color: $black;
  }
  .MuiTablePagination-actions {
    .MuiButtonBase-root {
      font-size: 24px !important;
      fill: $neutral !important;
      &.Mui-disabled {
        color: $disable !important;
        fill: $disable !important;
      }
    }
  }
}
.Mui-selected {
  background-color: transparent !important;
  &:hover {
    background-color: $background !important;
  }
}
.MuiMenuItem-root {
  &:hover {
    background-color: $background !important;
  }
}
.MuiTableContainer-root {
  max-height: none !important;
}
.MuiTable-root {
  .MuiTableHead-root {
    .MuiTableCell-head {
      background-color: $backgroundLight !important;
      color: $black !important;
    }
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      &:nth-child(odd) {
        .MuiTableCell-body {
          background-color: $white !important;
        }
      }
      &:nth-child(even) {
        .MuiTableCell-body {
          background-color: $backgroundLight !important;
        }
      }
      .MuiTableCell-body {
        .action-button {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          width: 44px;
          height: 44px;
          cursor: pointer;
          color: $white;
          position: relative;
          z-index: 10;
          &.selected-icon {
            background-color: $selectedIcon;
          }
          &.edit-icon {
            background-color: $editIcon;
          }
          &.delete-icon {
            background-color: $deleteIcon;
          }
          &.note-add-icon {
            background-color: $primary;
          }
        }
        &.txt-link {
          .MuiTypography-root {
            cursor: pointer;
            text-decoration: underline;
            color: $selectedIcon !important;
          }
        }
      }
    }
  }
  .cell-action {
    min-width: 180px;
    max-width: 180px;
    position: sticky;
    right: 0;
  }
}
@media (max-width: 1440px) {
  .MuiTable-root {
    .cell-action {
      &::before {
        content: "";
        box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.08);
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
      }
    }
  }
}
.min-w-100 {
  min-width: 100px;
}
.min-w-120 {
  min-width: 120px;
}
.min-w-160 {
  min-width: 160px;
}
.min-w-200 {
  min-width: 200px;
}
.min-w-250 {
  min-width: 250px;
}
