@import "./Variables.scss";

.ligation-static-table {
    border: 1px solid $neutral;
    border-right: 0;
    overflow: scroll;
    &-head {
        display: flex;
        align-items: center;
        &-item {
            color: $black;
            padding: $spacing * 2 + px;
            background-color: $backgroundLight;
            height: 100%;
            min-height: 20.41px;
            text-align: center;
        }
    }
    &-column {
        min-width: 200px;
        width: 25%;
        border-right: 1px solid $neutral;
    }
    &-body {
        &-row {
            display: flex;
            align-items: center;
            text-align: left;
        }
        &-item {
            padding: $spacing * 2 + px;
            color: $black;
            border-top: 1px solid $neutral;
        }
    }
}
