@import "./Button.scss";
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */
* {
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

// header
.header {
  position: sticky;
  top: 0;
  z-index: 99;
  &-container {
    height: 32px;
    padding: 16px 24px;
    background-color: $primary;
    display: flex;
    align-items: center;
  }
}
.header-popover-profile {
  &.MuiPopover-root {
    .MuiPaper-root {
      max-width: 200px;
      min-width: 200px;
      top: 52px !important;
    }
  }
}
// container
.MuiContainer-root {
  padding: 0 !important;
  height: 100%;
}
.h-full-screen {
  min-height: calc(100vh - 64px);
  height: 100%;
}
.max-h-full-screen {
  max-height: calc(100vh - 64px);
  overflow: scroll;
}
.cursor-pointer {
  cursor: pointer !important;
}
.object-fit-cover {
  width: 100%;
  object-fit: cover;
}
.object-fit-contain {
  width: 100%;
  object-fit: contain;
}
.aspect-ratio-16-9 {
  aspect-ratio: 16/9;
  @supports not (aspect-ratio: 16 / 9) {
    &::before {
      float: left;
      padding-top: 25%;
      content: "";
    }
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
}
.aspect-ratio-1-1 {
  aspect-ratio: 1/1;
  @supports not (aspect-ratio: 1 / 1) {
    &::before {
      float: left;
      padding-top: 100%;
      content: "";
    }
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
}
.word-break-word {
  max-width: 100%;
  word-break: break-word;
}
// link
a {
  text-decoration: none;
  cursor: pointer;
  &.txt-link {
    font-weight: 600;
    font-family: "Sarabun";
    color: $primary;
    cursor: pointer;
    font-size: 20px;
    line-height: 32px;
  }
}

// shadow
.shadow-xs {
  box-shadow: $shadowXs;
}
.shadow-sm {
  box-shadow: $shadowSm;
}
.shadow-md {
  box-shadow: $shadowMd;
}
.shadow-lg {
  box-shadow: $shadowLg;
}
.shadow-xl {
  box-shadow: $shadowXl;
}
.shadow-2xl {
  box-shadow: $shadow2xl;
}
.shadow-3xl {
  box-shadow: $shadow3xl;
}

// popover
.MuiPopover-root {
  .MuiPaper-root {
    padding: $spacing + px 0 !important;
    border-radius: 6px !important;
    box-shadow: $shadowSm !important;
    .MuiBox-root {
      a:hover {
        .MuiTypography-root {
          color: $primary;
          .MuiSvgIcon-root {
            font-size: 16px !important;
          }
        }
      }
      .MuiTypography-root {
        display: flex;
        align-items: center;
        .MuiSvgIcon-root {
          font-size: 16px !important;
          margin-right: $spacing + px !important;
          margin-left: 0 !important;
        }
      }
      .MuiButton-text {
        justify-content: flex-start;
      }
    }
  }
}
.sorting-popover {
  &-menu-item {
    cursor: pointer;
    color: $subHeader;
    &:hover {
      color: $primary;
    }
  }
}
