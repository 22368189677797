@import "./Variables.scss";

.file-upload {
  &-container {
    border: 1px solid $border;
    border-radius: 6px;
    border-style: dashed;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $black;
    cursor: pointer;
    min-height: 200px;
  }
  &-card {
    padding: $spacing + 2 + px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
